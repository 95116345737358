import styled from "styled-components"
import { Link } from "gatsby"
import BreakpointUp from "../../components/Media/BreakpointUp"

export const ViewLink = styled(Link)`
	display: block;	
	font-size:15px;
	font-weight:700;
	color:rgba(255,255,255,0.5);
	padding: 12px 0;
	white-space:nowrap;
	&:hover{		
		color:#fff;	
	}
	${BreakpointUp.xl`  
	color:#fff;
	padding:6px 8px;
	text-align:center;
	background: #020304;	
	border: 1px solid #020304;
	position:relative;
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);    
	transition-property: color;    
	transition-duration: 0.3s;
	margin: 20px 0;
	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #fff;
		transform: scaleX(0);
		transform-origin: 0 50%;
		transition-property: transform;
		transition-duration: 0.3s;
		transition-timing-function: ease-out;
	}

	&:hover{
		border-color:#ddd;	
		color:#030504;	
		&:before{
			transform: scaleX(1);
		}
	}
	`}
`