import React from 'react'

const CloseIcon = () => {
	return(
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20.001" viewBox="0 0 20 20.001">
		  <path id="Path_17694" data-name="Path 17694" d="M297.727,405.989h0a1.406,1.406,0,0,0,1-.413L306.3,398l7.573,7.573a1.407,1.407,0,0,0,1,.414h0a1.417,1.417,0,0,0,1-2.42L308.3,396l7.574-7.573a1.415,1.415,0,0,0-2-2L306.3,394l-7.573-7.573a1.415,1.415,0,0,0-2.437.952,1.4,1.4,0,0,0,.4,1.007L304.3,396l-7.573,7.572a1.416,1.416,0,0,0,1,2.419Z" transform="translate(-296.293 -385.988)" fill="#999"/>
		</svg>
	)
}

export default CloseIcon