import React from 'react'
import { Link } from 'gatsby'

import { ViewLink } from "../../Link"
import ArrowIcon from "../../Icons/ArrowIcon"
import { DropDown, DropItem } from "./tags-component"
import { Divider } from "../../section"

class BarnsDropdown extends React.Component {
	render(){
		return(
			<DropDown>				
				<DropItem>
					<Link to='/vertical-roof-barns'><span>Vertical Roof Barns</span><ArrowIcon /></Link>
				</DropItem>	
				<Divider />			
				<DropItem>
					<Link to='/a-frame-roof-barns'><span>A-Frame Roof Barns</span><ArrowIcon /></Link>
				</DropItem>
				<Divider />
				<DropItem>
					<Link to='/regular-roof-barns'><span>Regular Roof Barns</span><ArrowIcon /></Link>
				</DropItem>	
				<Divider />						
				<DropItem>
					<Link to='/continuous-roof-barns/'><span>Continuous Roof Barns</span><ArrowIcon /></Link>
				</DropItem>							
				<ViewLink to='/metal-barns'>View All Barns</ViewLink>			
			</DropDown>
		)
	}
}

export default BarnsDropdown