import React from 'react'
import { Link } from 'gatsby'
import { ViewLink } from "../../Link"
import ArrowIcon from "../../Icons/ArrowIcon"
import { DropDown, DropItem } from "./tags-component"
import { Divider } from "../../section"

class GaragesDropdown extends React.Component {
	render(){
		return(
			<DropDown>				
				<DropItem>
					<Link to='/vertical-roof-garages'><span>Vertical Roof Garages</span><ArrowIcon /></Link>
				</DropItem>	
				<Divider />			
				<DropItem>
					<Link to='/a-frame-roof-garages'><span>A-Frame Roof Garages</span><ArrowIcon /></Link>
				</DropItem>
				<Divider />
				<DropItem>
					<Link to='/regular-roof-garages'><span>Regular Roof Garages</span><ArrowIcon /></Link>
				</DropItem>							
				<ViewLink to='/metal-garages'>View All Garages</ViewLink>			
			</DropDown>
		)
	}
}

export default GaragesDropdown