import React from 'react'
import styled from 'styled-components'

const BaseButton = styled.button`
	display: inline-block;	
	text-align:center;
	padding:8px 20px;
	font-size:16px;
	font-weight:700;
	background: #020304;	
	border: 1px solid #020304;
	color:#fff;
	position:relative;

    vertical-align: middle;
    transform: perspective(1px) translateZ(0);    
    transition-property: color;    
    transition-duration: 0.3s;
	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #fff;
		transform: scaleX(0);
		transform-origin: 0 50%;
		transition-property: transform;
		transition-duration: 0.3s;
		transition-timing-function: ease-out;
	}
	> svg{
		height:20px;
		display:inline-block;
		vertical-align:middle;
		fill:#fff;
		+ .text{
			margin-left:5px;
		}
	}
	> .text{
		line-height:20px;
		display:inline-block;
		vertical-align:middle;
	}
	&:hover{
		border-color:#ddd;
		cursor: pointer;
		color:#030504;		
		> svg{
			fill:#030504;
		}
		&:before{
			transform: scaleX(1);
		}
	}
`



const FormButton = (props) => {
	const { icon, text } = props;
	return(
		<BaseButton className="btn" type="submit">
			{icon} <span className="text">{text}</span>
		</BaseButton>
	)
}

export default FormButton