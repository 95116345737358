import styled from "styled-components"
import BreakpointUp from "../../Media/BreakpointUp"

export const DropDown = styled.div`
	padding:0 40px;
	${BreakpointUp.xl`
	padding:20px 30px;     
	max-width: 298px;
	width:100%;
	position: absolute;
	background-color:#fff;
	box-shadow: 0px 2px 25px rgba(0,0,0,0.3);
  `}

`

export const DropItem = styled.div`
	font-weight: 500;
	font-size:16px;
	& svg {	
		line-height: 22px;
	}
	& a {
		display: flex;
		align-items: center;
		justify-content: space-between;
		line-height: 22px;
		color: rgba(255, 255, 255, 0.5);
		padding:12px 0;
		& span{
			position:relative;
			&:after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				display: block;
				height: 2px;
				transition: -webkit-transform .4s cubic-bezier(.77,0,.175,1);        
				transition: transform .4s cubic-bezier(.77,0,.175,1);
				transition: transform .4s cubic-bezier(.77,0,.175,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);        
				transform: translateZ(0) scaleX(1);
				background: #020304;
				transform-origin: right center;    
				transform: translateZ(0) scaleX(0);        
			}
		}
		& svg {
			transition: transform 0.3s ease-out;
		}
		&:hover {
			color: #fff;
			& span{
			&:after {
				transform-origin: left center;        
				transform: translateZ(0) scaleX(1);
			} 
		}   
			svg{				
				transform: translateX(4px);
			}  
		}
		${BreakpointUp.xl`  
		padding:15px 0;
		color: #020304;
		&:hover {
			color: #020304;
		}
		`}
	}	
`