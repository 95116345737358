import React from 'react'
import { Link } from "gatsby"
import algoliasearch from 'algoliasearch/lite'
import { connectHits, InstantSearch, SearchBox } from 'react-instantsearch-dom'
import styled from "styled-components"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

// Please note, that to get the `css` prop to work, you'll either
// need to add a jsx pragma or use a babel plugin. Consult the
// Emotion documentation for setting that up for your project.

const Result = styled.div`
  width: 300px;
  height: 200px;
  position: absolute;
  right: -20px;
  background: white;
  color: black;
  padding: 15px;
  overflow-y: scroll;
  display: block;
  flex-wrap: wrap;
  top: ${props=>props.top};
  box-shadow: 0 10px 30px rgba(0, 89, 178, 0.1);
  & h4:hover {
    color: blue;
  }
`

const CustomSearchBox = styled(SearchBox)`
  .ais-SearchBox-form {
    margin-bottom: 0px;
    background: black;
    border: 0px !important;
  }
  & input {
    width: 100% !important;
    background: black;
    color: white;
    border: 0px !important;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-style: italic;
      font-weight: 400;
    }
  }
  & button {
    display: none;
  }
`

const Hits = connectHits(({ hits, top }) =>{
  return(
    <Result top={top}>
      {hits.length ? (
        <>
          {hits.map(hit => {
            let title = hit.path
            if (title !== "/dev-404-page/" && title !== "/404.html" && title !== "/offline-plugin-app-shell-fallback/") {
              const pathName = title.split("/")
              let hitName = ''
              let index = pathName.length - 1
              if(pathName[index] === '') {
                hitName = pathName[index-1]
              } else {
                hitName = pathName[index]
              }
              const temp = hitName.split("-").map(item=>item.substring(0,1).toUpperCase()+item.substring(1)).join(" ")
              return (
                <div  key={hit.objectID} style={{ display: 'block', marginBottom: "5px" }}>
                  <Link
                    to={hit.path}
                  >
                    <h4 css={{ marginBottom: 0 }}>
                      {temp}
                    </h4>
                  </Link>
                </div>
              )
            }
          })}
        </>
      ) : (
        <p>There were no results for your query. Please try again.</p>
      )}
    </Result>
  )
})

export default class AlgoliaSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
    this.handleSearch = this.handleSearch.bind(this)
  }
  handleSearch(e) {
    if(e.target.value !== ""){
      this.setState({
        isVisible: true
      })
    } else {
      this.setState({
        isVisible: false
      })
    }
  }
  
  render(){
    const { top } = this.props
   return (
    <div>
      <InstantSearch
        indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
        searchClient={searchClient}
      >
        <CustomSearchBox 
          onChange={this.handleSearch}
          translations = {{
            placeholder: "Quick Search ..."
          }}
        />
        <div style={this.state.isVisible?{display: "block"} : {display: "none"}}>
          <Hits top={top} />
        </div>
      </InstantSearch>
    </div>
    )
  }
}