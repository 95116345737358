import React from 'react'
import { Link } from 'gatsby'

import ArrowIcon from "../../Icons/ArrowIcon"
import { DropDown, DropItem } from "./tags-component"
import { Divider } from "../../section"

class AboutDropdown extends React.Component {
	render(){
		return(
			<DropDown>				
				<DropItem>
					<Link to='/about-us'><span>About Us</span><ArrowIcon /></Link>
				</DropItem>	
				<Divider />			
				<DropItem>
					<Link to='/contact-us'><span>Contact Us</span><ArrowIcon /></Link>
				</DropItem>
			</DropDown>
		)
	}
}

export default AboutDropdown