import React from "react"
import styled from "styled-components"
import PhoneIcon from "../Icons/PhoneIcon"
import DefaultButton from "../button/DefaultButton"

const CallStickyFooter = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 0;
  box-shadow: 0px 0 15px 0px rgba(0, 0, 0, 0.5);
  @media (min-width: 768px) {
    display: none;
  }
  > a,
  .btn {
    display: flex;
    border-radius: 0;
    width: 100%;
  }
  .btn {
    padding: 10px 15px;
    justify-content: center;
    .icon > svg {
      width: 20px;
    }
  }
`;
const CallSticky = () => {
	return(						
        <CallStickyFooter>
            <a href="tel:8776005980" aria-label="8776005980"><DefaultButton icon={<PhoneIcon />} text="Call Us Now" /></a>
        </CallStickyFooter>
	)
}


export default CallSticky