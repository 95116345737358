import styled from 'styled-components'
import ListArrow from "../../images/list-arrow.svg"

export const Section = styled.section`
	padding-top: ${props=>props.pt};
	padding-bottom: ${props=>props.pb};
	background: ${props=>props.bg};
	margin: auto;
	margin-top: ${props=>props.mt};
	height:${props=>props.ht};
	text-align: ${props=>props.textAlign};	
	border-bottom:1px solid ${props=>props.bdrBottom};
	position: relative;	
	&:before{
		display:${props => (props.bgBefore ? 'block' : 'none')};
		content:'';
		position:absolute;
		background:${props => props.bgBefore};
		background-size:${props => props.bgSize};
	}
	&:after{
		display:${props => (props.bgAfter ? 'block' : 'none')};
		position:absolute;
		background:${props => props.bgAfter};
		background-size:${props => props.bgSize};
	}
	@media(max-width: 1200px){
		padding-top: ${props=>props.xpt};
		padding-bottom: ${props=>props.xpb};
	}
	@media(max-width: 767px){
		padding-top: ${props=>props.mpt};
		padding-bottom: ${props=>props.mpb};
	}
`
Section.defaultProps = {
	bg: "white",
	textAlign: "left",
	bdrBottom:"transparent"
}

export const SectionTitle = styled.h2`
	font-family: Roboto;
	font-size: 44px;
	font-weight: 800;
	line-height: 60px;

	max-width: ${props=>props.maxWidth};
	color: ${props=>props.color};
	text-align: ${props=>props.textAlign};
	margin-top: ${props=>props.mt};	
	margin-bottom: ${props=>props.mb};	
	margin-left:${props=>props.ml};
	margin-right:auto;
	width:100%;
	@media(max-width: 767px){
		font-size: 28px;
		line-height:38px;
	}
`

SectionTitle.defaultProps = {
	textAlign: "center",
	color: "#020304",
	maxWidth: "100%",
	mt: "auto",
	ml: "auto",
	mb: "10px",
}

export const Container = styled.div`
	max-width: ${props=>props.maxWidth};
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
`

Container.defaultProps = {
	maxWidth: "1380px",
}

export const SectionPageTitle = styled.h1`
	font-size: 38px;
	line-height:48px;
	font-weight: 700;
	max-width: ${props=>props.maxWidth};
	color: ${props=>props.color};	
	margin-top: ${props=>props.mt};	
	margin-bottom: ${props=>props.mb};	
	margin-left:${props=>props.ml};
	text-align: ${props=>props.textAlign};
	margin-right:auto;
	width:100%;
	@media(max-width: 767px){
		font-size: 28px;
		line-height:38px;
	}
`

SectionPageTitle.defaultProps = {
	color: "#020304",
	maxWidth: "100%",
	mt: "auto",
	ml: "auto",
	mb: "10px",
	textAlign: "center"
}


export const SectionLead = styled.p`
	font-size: ${props=>props.fontSize};
	line-height: 30px;
	margin:0 auto 20px auto;
	font-weight:700;
	text-align: ${props=>props.textAlign};
	max-width:${props=>props.maxWidth};
	color: ${props=>props.color};
	width:100%;
`
SectionLead.defaultProps = {
	textAlign: "center",
	maxWidth: "100%",
	color: "#666",
	fontSize: "18px"
}

export const SectionDescription = styled.div`
	margin-top: 12px;
	margin-left: auto;
	margin-right:auto;
	text-align: ${props=>props.textAlign};
	margin-bottom: ${props=>props.mb};
	max-width:${props=>props.maxWidth};
	width:100%;
	& p{
		font-size: 16px;
		line-height: 26px;
		color: #444;
		+ p{
			margin-top:20px;
		}
	}
`
SectionDescription.defaultProps = {
	textAlign: "center",
	mb: "30px",
	maxWidth: "100%"	
}

export const BreadCrumb = styled.div`	
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	color: #999;
	margin:0 -2px;
	position:relative;
	z-index:2;
	top: ${props=>props.top};
	margin-bottom: ${props=>props.mb};
	& a{
		padding:0 2px;
		color: #666;
		&:hover, &:focus{
			color:#231F20;
		}
	}
	& span {
		display: inline-block;
		padding:0 2px;
		color: #999;
	}
`
BreadCrumb.defaultProps = {
	top: "0px",
	mb: "0px",
}


export const MainPanel = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
`
export const RightPannel = styled.div`
	position:relative;	
	padding-left:15px;
	padding-right:15px;
	width:100%;
	@media(min-width: 992px){
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
`

export const LeftPannel = styled.div`
	position:relative;
	padding-left:15px;
	padding-right:15px;
	width:100%;
	@media(min-width: 992px){
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
`

export const MarkdownHeading = styled.div`	
	position:relative;
	text-align: ${props=>props.textAlign};
	max-width: ${props=>props.maxWidth};
	width:100%;
	margin:0 auto 30px auto; 
	& h1 {
		font-size: 34px;
		line-height:48px;
		font-weight: 700;
		margin-bottom:30px;
		color:#020304;
		@media(max-width: 767px){
			font-size: 28px;
			line-height:38px;
		}
	}
`
MarkdownHeading.defaultProps = {
	textAlign: "left",
	maxWidth: "auto"
}

export const MarkdownContent = styled.div`
	display: ${props=>props.display};
	max-width:${props=>props.maxWidth};
	width:100%;
	margin:0 auto;
	padding-left:${props=>props.pl};
	padding-right:${props=>props.pr};
	& h2 {
		margin-bottom: 15px;
	}
	& h3 {
		font-size: 28px;
		line-height: 38px;
		font-weight: bold;
		margin-bottom: 15px;
		@media(max-width: 767px){
			font-size: 24px;
			line-height:34px;
		}
	}

	& h4 {
		margin-bottom: 15px;
	}
	& a{
		display:inline-block;
		color: #020304;
    	font-weight: 700;
	}

	& p {
		text-align: ${props=>props.textAlign};
		color: ${props=>props.color};
		margin-bottom:20px;
		font-size: 16px;
		line-height: 26px;
	}
	& ul {
		list-style: none;
		margin:0 0 30px;
		padding:0;
		& li {
			padding-left:20px;
			vertical-align: text-top;
			color:#231F20;
			font-weight:400;
			margin:0;
			line-height: 26px;
			+ li{
				margin-top:5px;
			}
			&:before {
				content: url(${ListArrow});
				display: inline-block;
				margin-left: -20px;
				width: 20px;
				vertical-align: top;
			}
		}
	}
	& table {
		& thead {
			background: black;
			color: white;
			& th {
				padding: 10px 15px;
			}
		}
		& tbody {
			background: white;
			& td {
				padding-left: 15px !important;
			}
		}
	}
`
MarkdownContent.defaultProps = {
	textAlign: "left",
	maxWidth: "none",
	pl: "0",
	pr: "0",
	display: 'block',
}
export const SectionBanner = styled.section`
	padding:140px 0 90px;
	display: flex;
    flex-wrap:wrap;
    align-items: center;   
    background-image:${props => props.bgImg};
    background-repeat: no-repeat;
    background-position: 50% 100%;    
    background-size: cover;
	@media(max-width: 992px){
		padding:140px 0 60px;
	}
	&:after{		
		content: "";
		display:${props => (props.bgAfter ? 'block' : 'none')};
		position: absolute;				
		top:${props => props.topAfter};
		left:${props => props.leftAfter};
		right:${props => props.rightAfter};
		background-color:${props => props.bgAfter};
		width:${props => props.widthAfter};
		height:${props => props.HeightAfter};
	}
`

export const Badge = styled.div`
  background-color:${props=>props.bgColor};
  color:${props=>props.color};
  font-size:14px;
  line-height:18px;
  font-weight:700;
  padding:5px 15px;
  position:absolute;
  z-index:1;
  top:${props=>props.top};
  right:${props=>props.right};
  bottom:${props=>props.bottom};
  left:${props=>props.left};
`
Badge.defaultProps = {
	color:"#2F9800",
	bgColor:"rgba(47, 152, 0, 0.14)",
	top:"0",
	right:"auto",
	bottom:"auto",
	left:"0"
}


export const SectionHeader = styled.section`  
  border-bottom:1px solid #ddd;    
  padding-top: ${props=>props.pt};
  padding-bottom: ${props=>props.pb};
  background:${props=>props.bgColor};
  text-align:${props=>props.textAlign};

  @media(max-width: 991px){
    background:#f6f6f6;
  }

  @media(max-width: 1200px){
    padding-top: ${props=>props.xpt};
    padding-bottom: ${props=>props.xpb};
  }
`
SectionHeader.defaultProps = {
	bgColor:"#f6f6f6",
	textAlign:"left"
}

export const Divider = styled.div`
	width: 100%;
	height: 1px;
	margin: ${props=>props.my} 0;
	border-bottom: 1px solid ${props=>props.borderColor};
`
Divider.defaultProps = {
	my: "0px",
	borderColor: "rgba(2, 3, 4, 0.1)"
}

export const Triangle = styled.div`
	display: ${props=>props.display};
  position: relative;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0px 25px 65px rgba(1, 9, 32, 0.2);

  & :after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    margin-top: -49px;
    top: ${props=>props.top};
    left: ${props=>props.left};
    right: ${props=>props.right};
    box-sizing: border-box;
    border: 0.5em solid;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 4px -2px rgba(0,0,0,0.1)
  }
`

export const FormControlLabel = styled.div`
	&.active {
		font-size: 75%;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    color: #999;
	}
`