import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"

import DefaultButton from "../../components/button/DefaultButton"
import DefaultOutlineButton from "../../components/button/DefaultOutlineButton"
import HamburgerIcon from "../../components/Icons/HamburgerIcon"
import DownArrowIcon from '../../components/Icons/DownArrowIcon'
import SearchIcon from '../../components/Icons/SearchIcon'
import BuildingIcon from '../../components/Icons/BuildingIcon'
import QouteIcon from '../../components/Icons/QouteIcon'
import PhoneIcon from '../../components/Icons/PhoneIcon'
import BreakpointDown from "../../components/Media/BreakpointDown"
import AlgoliaSearch from "../../components/AlgoliaSearch"
import QuotePopup from '../QuotePopup'

import Logo from "../../images/logo.svg"

// import CarportsDropdown from './DropDown/carports'
import GaragesDropdown from './DropDown/garages'
import BarnsDropdown from './DropDown/barns'
import RVCoversDropdown from './DropDown/rv-covers'
import OtherBuildingsDropdown from './DropDown/other-buildings'
import ResourcesDropdown from './DropDown/resources'
import AboutDropdown from './DropDown/about'

const HeaderWrapper = styled.header`
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  background-color:#fff;    
  box-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
  ${BreakpointDown.xl`
    box-shadow:0 0px 8px rgba(0,0,0,0.2);
  `}
`
const Wrap = styled.div`
  display: ${props => props.display};
`
const Nav = styled.nav`
  background: #020304;
  margin:0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  ${BreakpointDown.xl`  
    padding: 40px 0;  
    flex-direction: column-reverse;
    width: 320px;
    position: absolute;
    top: 60px;
    right: 0;
    height: 100vh;
    z-index: 99;
    transition: all 0.3s ease 0s;
    transform: translate3d(320px,0,0);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 15px;
    overflow-y: auto;
  `}
`

const NavBrand = styled(Link)`  
  display: block;
  padding: 0px 10px;
  background-color: #fff;
  position: absolute;
  box-shadow: 0px 10px 45px rgba(0,0,0,0.3);
  left: 15px;
  top: 0;
  z-index: 100;
  overflow: hidden;
  height: 110px;
  width: 150px;
  & svg{
   width: 100%;
  }
  & img {
    width: 100%;
    padding-top: 10px;
  }
  @media(max-width: 1200px) {
    width: 100px;
    left: 0;
    height: 60px;
    padding: 0;
    & svg {
      margin-top: -5px
    }
    & img {
      margin-top: 5px;
    }
  }
  @media(max-width: 500px) {
    width: 70px;
    padding: 0 5px;
    & svg {
      margin-top: -20px
    }
    & img {
      margin-top: 0px;
    }
  }
`
const TopNav = styled.div`
  text-align: right;
  padding:10px 0;
  margin:0 -5px;
  & .quote {
    padding: 6px 12px;
    @media(min-width: 576px) {
      padding: 6px 18px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  > a{
    margin:0 5px;
    .btn{
      padding:6px 18px;
      ${BreakpointDown.lg`
        padding:6px 10px;
        .text{
          display:none;
        }
      `}
    }
    &:first-child{
      ${BreakpointDown.lg`
        display:none;
      `}
    }    
  }
  & .hamburger{
    display:none;
    & span {
      padding: 6px;
    }
    &:hover {
      cursor: pointer;
    }
    ${BreakpointDown.xl`
      display: inline-block;
      .text{
        display:none;
      }
    `}
  }
`

const NavbarNav = styled.div`
  display: flex;
  margin-left: auto;
  margin-right:0;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  align-items:center;
  ${BreakpointDown.xl`
    flex-direction:column;
    margin:0;
    width:100%;
    flex: auto;
  `}
`
const NavItem = styled.div`   
  margin:0 5px;  
  @media (min-width: 1280px) { 
    margin:0 15px;  
  }
  &:hover{
    cursor:pointer;    
  }
  ${BreakpointDown.xl`        
    width:100%;
  `}
  &.active{
    & .nav-link{
      color:#fff;
    }
  }
  &.nav-design{
    display:block;
    @media (min-width: 992px) { 
      display:none;
    } 
  }
`
const NavLink = styled.span`
  padding: 12px 10px;
  display: block;
  line-height: 26px;
  font-size: 16px;
  font-weight: 500;
  color:#fff;
  cursor:pointer;
  ${BreakpointDown.xl`    
    padding: 12px 24px;
  `}
  > svg{
    fill:#fff;
    margin-left: 5px;
    transform: rotate(0deg);
    transition: all 0.3s ease 0s;
  }
  &:hover{
    color:#fff; 
  }
  > a{
    color:#fff;
    &:hover{
      color:#fff; 
    }
  }
`
const NavSearch = styled.div`
  border-left: 1px solid rgba(255,255,255, 0.3);
  display: block;
  height: 100%;
  position: relative;
  padding: 11px 20px;
  ${BreakpointDown.xl`
    width:100%;
    border:none;
    height:auto;
  `}

  form {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  & input {
    width: 100% !important;
    background: black;
    color: white !important;
    border: 0px !important;
    &:focus {
      outline: none;
    }
    &::placeholder {
      font-style: italic;
      font-weight: 400;
      color: white !important;
    }
    .autocomplete-off {
      position: fixed !important;
      opacity: 0 !important;
      top: -1000px !important;
      left: -1000px !important;
      height: 0 !important;
      width: 0 !important;
      border: 0 !important;
    }
  }

  button {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: 0;

    svg {
      fill: #ffffff;
    }

    &:focus, &:active {
      outline: none;
    }
  }
`
const DropDown = styled.div`
  display: ${props => props.display}; 
`

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenu: true,
      // isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleRvCovers: false,
      isVisibleBarns: false,
      isVisibleOther: false,
      isVisibleResources: false,
      isVisibleAbout: false,
      isVisibleQuote: false,
      searchKey: null,
    }
    this.toggleActive = this.toggleActive.bind(this);
    this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this)
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({
      searchKey: ""
    })
  }
  componentWillUnmount() {
    document.body.classList.remove('menu-open')
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleSearchInputChange(e) {
    e.preventDefault();
    this.setState({
      searchKey: e.target.value
    })
  }
  handleSearchSubmit(e) {
    e.preventDefault();
    navigate("/search", { state: { searchKey: this.state.searchKey } });
  }
  handleClickOutside = event => {
    if (event.target.parentNode.tagName !== "A" && event.target.tagName !== "A") {
      this.setState({
        // isVisibleCarports: false,
        isVisibleGarages: false,
        isVisibleRvCovers: false,
        isVisibleBarns: false,
        isVisibleOther: false,
        isVisibleResources: false,
        isVisibleAbout: false
      })
    }
  };
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.add('popup-open')
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.remove('popup-open')
    }
  }
  toggleActive() {
    this.setState({
      isMenu: !this.state.isMenu
    })
    document.body.classList.toggle('menu-open', this.state.isMenu)
  }
  // showCarportsDropDown() {
  //   let flag = !this.state.isVisibleCarports
  //   this.setState({
  //     isVisibleCarports: flag,
  //     isVisibleGarages: false,
  //     isVisibleRvCovers: false,
  //     isVisibleBarns: false,
  //     isVisibleOther: false,
  //     isVisibleResources: false,
  //     isVisibleAbout: false
  //   })
  // }
  showGaragesDropDown() {
    let flag = !this.state.isVisibleGarages
    this.setState({
      // isVisibleCarports: false,
      isVisibleGarages: flag,
      isVisibleRvCovers: false,
      isVisibleBarns: false,
      isVisibleOther: false,
      isVisibleResources: false,
      isVisibleAbout: false
    })
  }
  showRVCoversDropDown() {
    let flag = !this.state.isVisibleRvCovers
    this.setState({
      // isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleRvCovers: flag,
      isVisibleBarns: false,
      isVisibleOther: false,
      isVisibleResources: false,
      isVisibleAbout: false
    })
  }
  showBarnsDropDown() {
    let flag = !this.state.isVisibleBarns
    this.setState({
      // isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleRvCovers: false,
      isVisibleBarns: flag,
      isVisibleOther: false,
      isVisibleResources: false,
      isVisibleAbout: false
    })
  }
  showOtherBuildingsDropDown() {
    let flag = !this.state.isVisibleOther
    this.setState({
      // isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleRvCovers: false,
      isVisibleBarns: false,
      isVisibleOther: flag,
      isVisibleResources: false,
      isVisibleAbout: false
    })
  }
  showResourcesDropDown() {
    let flag = !this.state.isVisibleResources
    this.setState({
      // isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleRvCovers: false,
      isVisibleBarns: false,
      isVisibleOther: false,
      isVisibleResources: flag,
      isVisibleAbout: false
    })
  }
  showAboutDropDown() {
    let flag = !this.state.isVisibleAbout
    this.setState({
      // isVisibleCarports: false,
      isVisibleGarages: false,
      isVisibleRvCovers: false,
      isVisibleBarns: false,
      isVisibleOther: false,
      isVisibleResources: false,
      isVisibleAbout: flag
    })
  }
  render() {
    const { isVisibleGarages, isVisibleRvCovers, isVisibleBarns, isVisibleOther, isVisibleResources, isVisibleAbout, isVisibleQuote } = this.state
    const { location } = this.props
    return (
      <HeaderWrapper>
        <Wrap className="container" display={isVisibleQuote ? "none" : "block"}>
          <TopNav>
            <Link to='/3d-steel-building-cost-estimator'><DefaultOutlineButton icon={<BuildingIcon />} text="Design your Building" /></Link>
            <span onClick={this.showQuote}>
              <DefaultOutlineButton className="quote" icon={<QouteIcon />} text="Free Quote" />
            </span>
            <a href='tel:8776005980'><DefaultButton animation="true" icon={<PhoneIcon />} text="(877) 600-5980" /></a>
            <span className="hamburger" onClick={this.toggleActive} ><DefaultButton icon={<HamburgerIcon />} /></span>
          </TopNav>
          <NavBrand to="/"><img src={Logo} alt="log" /></NavBrand>
          <Nav className="nav">
            <NavbarNav id="navMenu">
              {/* <NavItem onClick={()=>this.showCarportsDropDown()}>
                <NavLink className="nav-link">Carports<DownArrowIcon /></NavLink>
                <DropDown display={isVisibleCarports? "block" : "none"}>
                  <CarportsDropdown />
                </DropDown>
              </NavItem>*/}
              <NavItem onClick={() => this.showOtherBuildingsDropDown()}>
                <NavLink className="nav-link">Commercial Buildings<DownArrowIcon /></NavLink>
                <DropDown display={isVisibleOther ? "block" : "none"}>
                  <OtherBuildingsDropdown />
                </DropDown>
              </NavItem>
              <NavItem onClick={() => this.showGaragesDropDown()}>
                <NavLink className="nav-link">Garages<DownArrowIcon /></NavLink>
                <DropDown display={isVisibleGarages ? "block" : "none"}>
                  <GaragesDropdown />
                </DropDown>
              </NavItem>
              <NavItem onClick={() => this.showBarnsDropDown()}>
                <NavLink className="nav-link">Barns<DownArrowIcon /></NavLink>
                <DropDown display={isVisibleBarns ? "block" : "none"}>
                  <BarnsDropdown />
                </DropDown>
              </NavItem>
              {/* <NavItem onClick={() => this.showRVCoversDropDown()}>
                <NavLink className="nav-link">RV Covers<DownArrowIcon /></NavLink>
                <DropDown display={isVisibleRvCovers ? "block" : "none"}>
                  <RVCoversDropdown />
                </DropDown>
              </NavItem> */}
              <NavItem onClick={() => this.showResourcesDropDown()}>
                <NavLink className="nav-link">Resources<DownArrowIcon /></NavLink>
                <DropDown display={isVisibleResources ? "block" : "none"}>
                  <ResourcesDropdown />
                </DropDown>
              </NavItem>
              <NavItem onClick={() => this.showAboutDropDown()}>
                <NavLink className="nav-link">About Us<DownArrowIcon /></NavLink>
                <DropDown display={isVisibleAbout ? "block" : "none"}>
                  <AboutDropdown />
                </DropDown>
              </NavItem>
              <NavItem className="nav-design">
                <NavLink className="nav-link"><Link to='/3d-steel-building-cost-estimator'>Design Your Building</Link></NavLink>
              </NavItem>
            </NavbarNav>
            {!this.props.hideSearch &&
              <NavSearch>
                <form onSubmit={(e) => this.handleSearchSubmit(e)} autoComplete="off">
                  <input autoComplete="off" type="text" placeholder="Quick Search ..." id="search" name="search" onChange={(e) => this.handleSearchInputChange(e)} value={this.state.searchKey ? this.state.searchKey : ""} />
                  <button type="submit">
                    <SearchIcon />
                  </button>
                </form>
              </NavSearch>
            }
          </Nav>
        </Wrap>
        <QuotePopup
          isVisible={isVisibleQuote}
          onClose={this.onClosePopup}
          location={location}
        />
      </HeaderWrapper>
    )
  }
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
