import React from 'react'
import { Link } from 'gatsby'

import ArrowIcon from "../../Icons/ArrowIcon"
import { DropDown, DropItem } from "./tags-component"
import { Divider } from "../../section"

class ResourcesDropdown extends React.Component {
	render(){
		return(
			<DropDown>				
				<DropItem>
					<Link to='/rto'><span>RTO</span><ArrowIcon /></Link>
				</DropItem>	
				<Divider />			
				<DropItem>
					<Link to='/financing'><span>Financing</span><ArrowIcon /></Link>
				</DropItem>
				<Divider />
				<DropItem>
					<Link to='/site-preparation'><span>Site Preparation</span><ArrowIcon /></Link>
				</DropItem>	
				<Divider />		
				<DropItem>
					<Link to='/order-process'><span>Building Order Process</span><ArrowIcon /></Link>
				</DropItem>	
				<Divider />		
				<DropItem>
					<Link to='/metal-building-roof-styles'><span>Building Roof Styles</span><ArrowIcon /></Link>
				</DropItem>	
				<Divider />		
				<DropItem>
					<Link to='/blog'><span>Blog</span><ArrowIcon /></Link>
				</DropItem>
				<Divider />			
				<DropItem>
					<Link to='/faq'><span>FAQs</span><ArrowIcon /></Link>
				</DropItem>			
			</DropDown>
		)
	}
}

export default ResourcesDropdown