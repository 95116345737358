import React from 'react'
import { Link } from 'gatsby'
import { ViewLink } from "../../Link"
import ArrowIcon from "../../Icons/ArrowIcon"
import { DropDown, DropItem } from "./tags-component"
import { Divider } from "../../section"

class OtherBuildingsDropdown extends React.Component {
	render(){
		return(
			<DropDown>				
				<DropItem>
					<Link to='/custom-metal-buildings'><span>Custom Buildings</span><ArrowIcon /></Link>
				</DropItem>	
				{/* <Divider />			
				<DropItem>
					<Link to='/commercial-metal-buildings'><span>Commercial Buildings</span><ArrowIcon /></Link>
				</DropItem> */}
				<Divider />
				<DropItem>
					<Link to='/steel-utility-buildings'><span>Utility Buildings</span><ArrowIcon /></Link>
				</DropItem>		
				<ViewLink to='/commercial-metal-buildings'>View All Commercial Buildings</ViewLink>				
			</DropDown>
		)
	}
}

export default OtherBuildingsDropdown