import React from 'react'
import styled from 'styled-components'

const BaseButton = styled.span`
	display: inline-block;	
	text-align:center;
	padding:8px 20px;
	font-size:16px;
	font-weight:700;
	background-color:#fff;
	border: 1px solid #ddd;
	color:#020304;
	vertical-align: middle;
    transform: perspective(1px) translateZ(0);    
    transition-property: color;    
    transition-duration: 0.3s;
	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #020304;
		transform: scaleX(0);
		transform-origin: 0 50%;
		transition-property: transform;
		transition-duration: 0.3s;
		transition-timing-function: ease-out;
	}
	> svg{
		height:20px;
		display:inline-block;
		vertical-align:middle;
		fill:#666;
		+ .text{
			margin-left:5px;
		}
	}
	> .text{
		line-height:20px;
		display:inline-block;
		vertical-align:middle;
	}
	&:hover{		
		color:#fff;
		> svg{
			fill:#fff;
		}
		&:before{
			transform: scaleX(1);
		}
	}
`

const DefaultOutlineButton = (props) => {
	const { icon, text, className } = props;
	return(
		<BaseButton className={`btn ${className}`}>
			{icon} <span className="text">{text}</span>
		</BaseButton>
	)
}

export default DefaultOutlineButton